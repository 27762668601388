<template>
<div>
  <header id="topnav" :class="{'nav-sticky': stickyHeader,'not-sticky': !stickyHeader,'mobile-view': mobileView}" class="defaultscroll sticky">
      <div class="container">
          <div>
              <a class="logo" href="#">
                  <img src="imgs/keyko-logo.png" height="40" alt="" v-show="stickyHeader || mobileView" v-scroll-to="'#home'">
                  <img src="imgs/keyko-logo-white.png" height="40" alt="" v-show="!stickyHeader && !mobileView" v-scroll-to="'#home'">
              </a>
          </div>
          <div class="menu-extras">
              <div class="menu-item">
                  <a class="navbar-toggle" @click="toggleMenu()" :class="{'open': isCondensed === true}">
                      <div class="lines">
                          <span></span>
                          <span></span>
                          <span></span>
                      </div>
                  </a>
              </div>
          </div>
          <div id="navigation">
              <ul class="navigation-menu">
                  <li>
                      <a href="javascript: void(0);" v-scroll-to="'#security'">Cyber Security</a>
                  </li>
                  <li>
                      <a href="javascript: void(0);" v-scroll-to="'#development'">Development</a>
                  </li>
                  <li>
                      <a href="javascript: void(0);" v-scroll-to="'#books'">Books</a>
                  </li>
                  <li>
                      <a href="javascript: void(0);" v-scroll-to="'#contact'">Contact</a>
                  </li>
              </ul>
          </div>
      </div>
  </header>
  <div v-scroll-spy>
    <section id="home" class="bg-half-260 bg-primary d-table w-100" style="background: url('imgs/enterprise.jpg') center center;">
        <div class="bg-overlay"></div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="title-heading text-center mt-4">
                        <h1 class="display-4 title-dark font-weight-bold text-white mb-3">Start Something Brilliant</h1>
                        <p class="para-desc mx-auto text-white">Keyko is an Australian business that specialises in a variety of services. From Cyber Security services to Custom Development Projects, we are well equiped to handle any task.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <section class="section pb-0" id="security">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="title-heading mb-4 pb-2">
                        <h2 class="heading mb-4">Cyber Security Services</h2>
                        <p class="text-muted para-desc mx-auto mb-0">The requirements to protect and secure your business, employees and network can change on a daily basis. Keyko can help in uplifting capabilities and offering leading edge industry knowledge.</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 col-12 mt-5">
                    <div class="features">
                        <div class="image position-relative d-inline-block">
                            <img src="images/icon/computer.svg" class="avatar avatar-small" alt="">
                        </div>

                        <div class="content mt-4">
                            <h4 class="title-2">Penetration Testing</h4>
                            <p class="text-muted mb-0">Identify weaknesses in your business systems. Internal, external and web application pen testing can help harden your presence to the outside world. Our experienced team will work tirelessly to try to break and test your systems to it's limits.</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-12 mt-5">
                    <div class="features">
                        <div class="image position-relative d-inline-block">
                            <img src="images/icon/database.svg" class="avatar avatar-small" alt="">
                        </div>

                        <div class="content mt-4">
                            <h4 class="title-2">Application Security</h4>
                            <p class="text-muted mb-0">From custom build applications to off-the-shelf offerings, it may not always be known what risks may be present today or in the future. Our team will help you to identify, resolve and prevent threats and vulnerabilities.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 col-12 mt-5">
                    <div class="features">
                        <div class="image position-relative d-inline-block">
                            <img src="images/icon/graduation-hat.svg" class="avatar avatar-small" alt="">
                        </div>

                        <div class="content mt-4">
                            <h4 class="title-2">Awareness, Training & Education</h4>
                            <p class="text-muted mb-0">How aware are you and your employees of today's cyber threats? Do you know your essential 8? Remove the large task of ensuring all your employees are well trained up to date. let our team ensure your staff can detect, prevent and take action.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12 mt-5">
                    <div class="features">
                        <div class="image position-relative d-inline-block">
                            <img src="images/icon/customer-service.svg" class="avatar avatar-small" alt="">
                        </div>

                        <div class="content mt-4">
                            <h4 class="title-2">Red Team Testing</h4>
                            <p class="text-muted mb-0">How will your business handle a real attack? Our Red Team will put themselves in the position of motivated attackers. They will test your business and staff through; Social Engineering, On-site access, reconnaissance, remote access and more. </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12 mt-5">
                    <div class="features">
                        <div class="image position-relative d-inline-block">
                            <img src="images/icon/clipboard.svg" class="avatar avatar-small" alt="">
                        </div>

                        <div class="content mt-4">
                            <h4 class="title-2">Incident Response Planning</h4>
                            <p class="text-muted mb-0">Small business is the target of 43% of all cyber crimes in Australia. What will you do when you have a cyber incident? We can help devise a response plan to a possible cyber security incident. It's not a matter of "if" but "when".</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12 mt-5">
                    <div class="features">
                        <div class="image position-relative d-inline-block">
                            <img src="images/icon/shield.svg" class="avatar avatar-small" alt="">
                        </div>

                        <div class="content mt-4">
                            <h4 class="title-2">Security Risk Assessment & Testing</h4>
                            <p class="text-muted mb-0">A comprehensive cyber risk assessment can allow your organisation to identify risks and potential gaps in its environment. Helping you to implement a cost-effective cyber security solution.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>





        <div class="container mt-100 mt-60">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-6 text-center">
                  <div class="position-relative" style="z-index: 1">
                      <img src="imgs/ssltrust-laptop.png" class="rounded img-fluid mx-auto d-block" alt="">
                      <div class="play-icon">
                          <a href="javascript: void(0);" v-b-modal.ssltrust-modal class="play-btn btn-pills btn-soft-primary mt-4 video-play-icon">
                              <play-icon class="fea icon-ex-md text-black title-dark"></play-icon>
                          </a>
                      </div>
                      <b-modal id="ssltrust-modal" hide-footer size="lg" header-close-variant="white" header-class="border-0" content-class="border-0" centered>
                          <youtube video-id="WDJIfRUYvwc" :fitParent="true" :resize="true" ref="youtube"></youtube>
                      </b-modal>
                  </div>
                  <img src="imgs/certificate-authorities.png" class="mt-5" height="96" alt="Certificate Authorities">
                </div>
                <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="section-title ml-lg-5">
                      <img src="imgs/ssltrust-logo.png" width="250" alt="SSLTrust">
                        <p class="text-muted">Australia's No.1 SSL/TLS Certificate and Website Security Provider.</p>
                        <p class="text-muted mb-10">SSLTrust provides SSL Certificates to help encrypt, secure and enhance your customer trust. We have well established partnerships with leading Authorities including Comodo, GeoTrust and DigiCert. And we bring you the leading products and solutions at fantastic prices.</p>
                        <div class="mt-4">
                            <a href="https://www.ssltrust.com.au" target="_blank" class="text-primary p-1 px-2 shadow rounded">Visit SSLTrust.com.au <i class="mdi mdi-chevron-right"></i></a><br>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="development">
      <div class="mt-100 mt-60">
          <div style="background: url('imgs/software-bg.jpg') center center;background-size: cover; ">
              <div class="container py-md-5 py-3">
                  <div class="row bg-white rounded">
                    <div class="card border-0">
                        <div class="card-body p-md-5 p-4 bg-white rounded">
                          <div class="row align-items-center mb-5">
                              <div class="col-lg-7 col-md-7 order-1 order-md-2">
                                  <div class="title-heading mt-4 ml-lg-5">
                                      <h2 class="heading mb-3">Custom <span class="text-primary">Development</span></h2>
                                      <p class="para-desc text-muted">Development of your new idea can be a time consuming, stressful endeavour. Going from idea to launch will be easier and quicker with our experienced developers and project management services.</p>
                                      <p class="para-desc text-muted">We have a large range of skills in various platforms and programming languages. Including (but not limited to): NodeJS, Javascript, PHP, SQL, HTML, CDNs and Proxies, Load Balances and more.</p>
                                  </div>
                              </div>

                              <div class="col-lg-5 col-md-5 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                  <img src="images/illustrator/working_with_computer.svg" class="img-fluid" alt="">
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-lg-6 col-md-12 pl-0 pr-0">
                          <div class="p-md-5 p-4 bg-white rounded">
                            <h3 class="heading mb-3">Project Managers</h3>
                            <p class="para-desc text-muted">We put together all the steps to get from where you are to a finished project with each step having an established process.
      Good communication can mean the success of a project with everyone communicating clearly throughout the process. We make it a habit to stay in touch with every stakeholder with Internal Status Meetings, Client Status Meetings and the use of project tracking software.
      Our exeprienced project managers can not only help manage developers we provide but also your own developers and team members</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 pr-0">
                      <div class="p-md-5 p-4 bg-white rounded">
                      <h3 class="heading mb-3">Technical Advisors, Team Leaders</h3>
                      <p class="para-desc text-muted">You have an idea but your not sure how to make it a reality? Maybe you have hit a road block in your project? We have an extensive pool of talented and experienced web developers, engineers and system designers to help you when you need it most.
Our advisors can stick with you through the whole project to get it to production stage and beyond. They wont just give you the stock standard advice. They will research, test and deliver real world solutions tailored to exactly what you need.</p>
                    </div>
                  </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <section class="py-4 border-bottom border-top">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                    <img src="imgs/worked-with-fpmarkets.png" height="60" alt="">
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                    <img src="imgs/worked-with-nsw-education.png" height="60" alt="">
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                    <img src="imgs/worked-with-bendigo.png" height="60"alt="">
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                    <img src="imgs/worked-with-maccentre.png" height="60" alt="">
                </div>
            </div>
        </div>
    </section>
    <section id="books">
      <div class="container mt-100 mt-60">
          <div class="row align-items-center">
              <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
                  <div class="section-title mr-lg-4">
                      <h2 class="title mb-3">SSL/TLS<br>Under Lock and Key</h2>
                      <p class="para-desc text-muted">If you are looking for a comprehensive, soup-to-nuts resource on SSL/TLS, look no further. This book, geared towards bridging the gap between the absolute beginner and the veteran IT Professional, combines the theoretical and the practical in equal measure.</p>
                      <p class="para-desc text-muted">Covering topics such as:
                        <ul>
                          <li>Symmetric vs Asymmetric Cryptography</li>
                          <li>One-Way Functions</li>
                          <li>Digital Signatures</li>
                          <li>Key Generation</li>
                          <li>History and Context</li>
                          <li>Generating a Certificate Signing Request (CSR)</li>
                          <li>Converting Certificates Between Different Formats</li>
                          <li>Configuring Servers</li>
                          <li>and more...</li>
                        </ul>
                      </p>
                          <div class="m-4">
                              <a href="https://www.amazon.com.au/s?k=9780648931607" target="_blank" class="btn btn-outline-primary">Buy Paperback (Australia) <i class="mdi mdi-chevron-right"></i></a><br>
                              <a href="https://www.amazon.com/s?k=9780648931607" target="_blank" class="btn btn-outline-primary mt-2">Buy Paperback (USA) <i class="mdi mdi-chevron-right"></i></a>
                          </div>
                          <p class="para-desc text-muted">eBook and Audiobook coming soon.</p>
                  </div>
              </div>

              <div class="col-lg-7 order-1 order-lg-2">
                  <div class="saas-feature-shape-right position-relative">
                      <img src="imgs/under-lock-and-key-book.png" class="img-fluid mx-auto d-block" alt="">
                  </div>
              </div>
          </div>
      </div>
    </section>
    <section class="section bg-light" id="contact">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">Get In Touch !</h4>
                        <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary font-weight-bold">Keyko</span> today on your new or exisiting project.<br>Or just reach out and say hi.</p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-6 mt-4 pt-2 order-2 order-md-1">
                    <div class="card rounded shadow border-0">
                        <div class="card-body">
                            <div class="custom-form bg-white">
                                <div v-show="contactFormSent" class="text-center">
                                    <div class="image">
                                      <img src="images/icon/mail.svg" class="avatar avatar-medium" alt="">
                                  </div>
                                  <h6 class="font-weight-bold mt-5">Message Sent!</h6>
                                  <p>Thank you for contacting us. We will be sure to respond to your enquiry asap. Usually within 1 business day.</p>
                                </div>
                                <form name="contact-form" id="contact-form" v-show="!contactFormSent">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group position-relative">
                                                <label>Your Name <span class="text-danger">*</span></label>
                                                <user-icon class="fea icon-sm icons"></user-icon>
                                                <input name="name" v-model="contactForm.name" id="name" type="text" class="form-control pl-5" placeholder="First Name :" :disabled="contactFormSending">
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group position-relative">
                                                <label>Your Email <span class="text-danger">*</span></label>
                                                <mail-icon class="fea icon-sm icons"></mail-icon>
                                                <input name="email" v-model="contactForm.email" id="email2" type="email" class="form-control pl-5" placeholder="Your email :" :disabled="contactFormSending">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group position-relative">
                                                <label>Comments <span class="text-danger">*</span></label>
                                                <message-circle-icon class="fea icon-sm icons"></message-circle-icon>
                                                <textarea name="comments" v-model="contactForm.message" id="comments" rows="4" class="form-control pl-5" placeholder="Your Message :" :disabled="contactFormSending"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 text-center">
                                          <div class="alert alert-danger" v-show="showContactFormError"> {{contactFormError}} </div>
                                            <b-button variant="primary" @click="submitContact" :disabled="contactFormSending"><b-spinner small v-show="contactFormSending"></b-spinner> Send Message</b-button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-6 mt-4 pt-2 order-1 order-md-2">
                    <div class="title-heading ml-lg-4">
                        <h4 class="mb-4">Contact Details</h4>
                        <div class="card border-0 bg-transparent">
                            <div class="card-body p-0">
                                <div class="contact-detail d-flex align-items-center mt-3">
                                    <div class="icon">
                                        <mail-icon class="fea icon-m-md text-dark mr-3"></mail-icon>
                                    </div>
                                    <div class="content overflow-hidden d-block">
                                        <h6 class="font-weight-bold mb-0">Email</h6>
                                        <a href="mailto:contact@keyko.com.au" class="text-primary">contact@keyko.com.au</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 bg-transparent">
                            <div class="card-body p-0">
                                <div class="contact-detail d-flex align-items-center mt-3">
                                    <div class="icon">
                                        <phone-icon class="fea icon-m-md text-dark mr-3"></phone-icon>
                                    </div>
                                    <div class="content overflow-hidden d-block">
                                        <h6 class="font-weight-bold mb-0">Phone</h6>
                                        <a href="tel:+61281230992" class="text-primary">(+61) 2 8123 0992</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 bg-transparent">
                            <div class="card-body p-0">
                                <div class="contact-detail d-flex align-items-center mt-3">
                                    <div class="icon">
                                        <map-pin-icon class="fea icon-m-md text-dark mr-3"></map-pin-icon>
                                    </div>
                                    <div class="content overflow-hidden d-block">
                                        <h6 class="font-weight-bold mb-0">Location</h6>
                                        Sydney, Australia
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
  </div>
</div>
</template>
<script>
import {
    CrosshairIcon,
    MapPinIcon,
    MailIcon,
    PhoneIcon,
    MessageCircleIcon,
    UserIcon,
    PlayIcon
} from 'vue-feather-icons'
import 'whatwg-fetch'

export default {
    data() {
        return {
            isCondensed: false,
            stickyHeader: false,
            windowWidth: window.innerWidth,
            mobileView: false,
            contactForm: {
              name: '',
              email: '',
              message: ''
            },
            contactFormError: '',
            showContactFormError: false,
            contactFormSent: false,
            contactFormSending: false
        }
    },
    components: {
        CrosshairIcon,
        PhoneIcon,
        MapPinIcon,
        MailIcon,
        MessageCircleIcon,
        UserIcon,
        PlayIcon
    },
    created () {
      if (window.innerWidth < 992) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
      window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('resize', this.handleResize)
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        submitContact() {
          var self = this
          this.contactFormError = ''
          if (!this.contactForm.name) this.contactFormError += 'Contact Name is Required! '
          if (!this.contactForm.email) this.contactFormError += 'Contact Email is Required! '
          if (!this.contactForm.message) this.contactFormError += 'Contact Message is Required! '
          if (this.contactFormError) {
            this.showContactFormError = true
            return false
          } else {
            this.showContactFormError = false
          }
          this.contactFormSending = true
          var data = new FormData()
          data.append('ticket[name]', this.contactForm.name)
          data.append('ticket[email]', this.contactForm.email)
          data.append('ticket[subject]', 'Keyko Enquiry')
          data.append('ticket[message]', this.contactForm.message)
          data.append('checkf', '')
          fetch('https://keyko.sirportly.com/remote_form/1647', {
            method: 'POST',
            body: data,
            mode: 'no-cors'
          }).then(function(resp) {
            console.log(resp)
            self.contactForm.name = self.contactForm.email = self.contactForm.message = ''
            self.contactFormSent = true
            self.contactFormSending = false
          }).catch(function() {
            self.contactFormError = 'Sorry, there was an error sending your enquiry.'
            this.showContactFormError = true
            self.contactFormSending = false
          })
        },
        handleResize (event) {
          this.windowWidth = window.innerWidth
          if (window.innerWidth < 992) {
            this.mobileView = true
          } else {
            this.mobileView = false
          }
        },
        handleScroll (event) {
          if (
              document.body.scrollTop > 50 ||
              document.documentElement.scrollTop > 50
          ) {
              this.stickyHeader = true
          } else {
              this.stickyHeader = false
          }
        },
        toggleMenu() {
            this.isCondensed = !this.isCondensed;
            if (this.isCondensed) {
                document.getElementById("navigation").style.display = "block";
            } else
                document.getElementById("navigation").style.display = "none";
        },
    }
  }
</script>
<style>
.modal-content {
  background-color: transparent;
}
#topnav.not-sticky:not(.mobile-view) .navigation-menu a{
  color: white;
}
#topnav.not-sticky:not(.mobile-view) .navigation-menu a:hover{
  color: #41c78d!important;
}
</style>
